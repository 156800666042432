<template>
  <k-ajax-multi-select
    :value="value"
    @input="$emit('input', $event)"
    :multiple="multiple"
    labelBy="Text"
    :validator="validator"
    :getOptions="GetSzervezetek"
    :clear-on-select="true"
    :close-on-select="true"
    placeholder="Kezdjen el gépelni..."
    tagPlaceholder="Kezdjen el gépelni..."
  >
    <template slot="singleLabel" slot-scope="props">
      <div>{{ props.option.Text }}</div>
    </template>
    <template slot="option" slot-scope="props">
      <div>{{ props.option.Text }}</div>
    </template>
    <template slot="noOptions">
      Nincs találat
    </template>
  </k-ajax-multi-select>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppFunctions } from '../../functions/AppFunctions';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { appService } from '../../services/AppService';
import { AppStoreTypes } from '../../store/modules/app';

export default {
  name: 'szervezet-valaszto',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    async GetSzervezetek(keresoszoveg) {
      let options = [];

      if (keresoszoveg.length > 2) {
        try {
          let szervezetek = await appService.GetSzervezetekByKeresoSzoveg({
            keresoszoveg: keresoszoveg,
            admin: this.isSzerkeszto,
          });

          options = szervezetek
            .filter(f =>
              this.isSzerkeszto ? true : f.Id == this.user?.Szervezet?.Id
            )
            .map(m => {
              return { Id: m.Id, Text: m.Text };
            });
        } catch (error) {
          NotificationFunctions.AjaxError({ error });
        }
      }
      return options;
    },
  },
  computed: {
    ...mapGetters({
      //szervezetek: AppStoreTypes.szervezetek.getAll,
      user: AppStoreTypes.user.get,
    }),
    // szervezetekFiltered() {
    //   if (this.isSzerkeszto) {
    //     return this.szervezetek;
    //   }
    //   return this.szervezetek.filter(f => f.Id == this.user?.Szervezet?.Id);
    // },
    isSzerkeszto() {
      return AppFunctions.IsSzerkeszto(this.user);
    },
  },
  watch: {},
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
